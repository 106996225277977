var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { JCBLogo } from './JCBLogo';
import { JCBSmallLogo } from './JCBSmallLogo';
import { joinPath } from './paths';
import { padsModule } from '@cognitranlimited/parts-itis-web';
import { campaignsModule } from '@cognitranlimited/itis-web/dist/campaigns';
import { transformVin } from './vinTransformer';
import { elandersModule } from './elanders/elandersModule';
import { ssoConfigModule } from './ssoConfigModule';
import { livelinkModule } from './livelink';
import { termsModule } from './terms/termsModule';
import { getStringsFileLanguage } from './strings';
import { productInfoModule } from './productInfo/productInfoModule';
import { getGlobalProperty } from './utils';
import { ProductToggleIcon } from './productToggleIcon/ProductToggleIcon';
function getModules() {
    var elanders = (function () {
        var portalUri = global.iwaProperties.elandersPrintPortalUri;
        var documentTypeIds = global.iwaProperties.elandersPrintPortalDocumentTypeCodes;
        if (portalUri && documentTypeIds) {
            return elandersModule({
                documentTypeIds: documentTypeIds.toUpperCase().split(',').map(function (id) { return id.trim(); }),
                getPortalUri: function (sourceId) {
                    return portalUri.replace('{sourceId}', sourceId);
                }
            });
        }
    })();
    var modules = [
        campaignsModule({
            overrides: {
                strings: function (language) {
                    return __awaiter(this, void 0, void 0, function () {
                        var _a;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    _b.trys.push([0, 2, , 3]);
                                    return [4 /*yield*/, import("./strings/campaigns/".concat(getStringsFileLanguage(language), ".strings"))];
                                case 1: return [2 /*return*/, (_b.sent()).default];
                                case 2:
                                    _a = _b.sent();
                                    return [2 /*return*/, null];
                                case 3: return [2 /*return*/];
                            }
                        });
                    });
                }
            }
        }),
        livelinkModule(),
        termsModule(),
        productInfoModule(),
        ssoConfigModule(),
        padsModule({
            apiBaseUrl: global.iwaProperties.apiUrl,
            padsBasketsUrl: "/service/pads/basket",
            awsApiUrl: global.iwaProperties.padsApiUrl,
            searchServiceUrl: global.iwaProperties.searchServiceUrl,
            documentGroupId: global.iwaProperties.padsDocumentGroupId
        })
    ];
    if (elanders) {
        modules.push(elanders);
    }
    return modules;
}
export var spProps = function () { return ({
    apiUrl: getGlobalProperty('apiUrl'),
    itisUrl: getGlobalProperty('itisUrl'),
    authClientId: getGlobalProperty('authClientId'),
    authSecret: getGlobalProperty('authSecret'),
    vehiclePlateRecognitionEndpoint: joinPath(getGlobalProperty('vprUrl'), 'recognize'),
    stringsLoader: function (language) { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 4]);
                    return [4 /*yield*/, import("./strings/".concat(getStringsFileLanguage(language), ".strings"))];
                case 1: return [2 /*return*/, (_b.sent()).default];
                case 2:
                    _a = _b.sent();
                    return [4 /*yield*/, import("./strings/en.strings")];
                case 3: return [2 /*return*/, (_b.sent()).default];
                case 4: return [2 /*return*/];
            }
        });
    }); },
    helpStringsLoader: function (language) { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 4]);
                    return [4 /*yield*/, import("./strings/help/".concat(getStringsFileLanguage(language), "Help.strings"))];
                case 1: return [2 /*return*/, (_b.sent()).default];
                case 2:
                    _a = _b.sent();
                    return [4 /*yield*/, import("./strings/help/enHelp.strings")];
                case 3: return [2 /*return*/, (_b.sent()).default];
                case 4: return [2 /*return*/];
            }
        });
    }); },
    vehiclePanelOrder: ['documentGroupLookup', 'notices', 'importantDocuments', 'favouriteDocuments'],
    hideModelSelectionByDefault: true,
    hideDocumentGlobalReferenceColumn: true,
    showNewVehicleLookupWorkflow: true,
    isSearchViaDocumentGroupDetailedSelectOptionShown: true,
    userStatusUpdateInterval: 300000,
    alwaysBypassVehicleSpecificSubscriptionSearch: true,
    showModelSelectionWhenVinNotFound: true,
    apiTimeout: 60000,
    sidebarLogo: function () { return _jsx(JCBLogo, {}); },
    mobileSidebarLogo: function () { return _jsx(JCBSmallLogo, {}); },
    vehicleToggleIcon: function () { return _jsx(ProductToggleIcon, {}); },
    modules: getModules(),
    vinResolver: transformVin,
    documentViewEventUrl: process.env.NODE_ENV === 'production'
        ? getGlobalProperty('documentEventUrl')
        : undefined
}); };
